<template>
  <section>
    <div class="content">

      <div class="switch-report-type">
        <label>Форма отчета:</label>
        <div class="double-switch">
          <button :class="reportType===1?'active':''" @click="changeReportTypeEvent(1)">Электронный</button>
          <button :class="reportType===2?'active':''" @click="changeReportTypeEvent(2)">На бумаге</button>
        </div>
      </div>

      <div class="services">
        <div v-for="(object,objectKey) in cartObjects" :key="object.id" class="service-item">
          <div class="service-item-header">
            <div class="name">
              <router-link :to="'/oc/' + object.page.alias">
                {{ objectKey+1 }}. {{object.name}}
              </router-link>
              <font-awesome-icon icon="info-circle" />
            </div>
          </div>
          <div class="service-item-body">
            <div class="image">
              <img :src="object.image">
            </div>
            <div class="price">
              <template v-if="!object.priceSale">
                <span>{{object.price}} руб.</span>
              </template>
              <template v-else>
                <span class="price_sale">{{object.price}} руб.</span>
                <span>{{object.priceSale}} руб.</span>
              </template>
              <p v-if="reportType===2">+{{ paperReportPrice }} руб.</p>
            </div>
            <div class="action">
              <button class="btn btn-custom" @click="deleteEvent(object.dbIndex)">
                <font-awesome-icon icon="trash" />
              </button>
            </div>
          </div>
          <div class="service-item-footer">
            <div class="prompt">
              <div><b>Потребуются документы:</b></div>
              <div v-html="object.prompt"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="price">
        <b>
          Итого: {{price}} руб.
          <span v-if="price>2000" class="cart-info">
            При сумме заказа более 2000 руб. потребуется предоплата 50%.
          </span>
          <span v-if="price>5000 && price<=10000" class="cart-info">
            При сумме заказа более 5000 руб. по данному заказу будет дополнительная скидка 5%.
          </span>
          <span v-else-if="price>10000 && price<=15000" class="cart-info">
            При сумме заказа более 10000 руб. по данному заказу будет дополнительная скидка 10%.
          </span>
          <span v-else-if="price>15000 && price<=20000" class="cart-info">
            При сумме заказа более 15000 руб. по данному заказу будет дополнительная скидка 15%.
          </span>
          <span v-else-if="price>20000" class="cart-info">
            При сумме заказа более 20000 руб. по данному заказу будет дополнительная скидка 20%.
          </span>
        </b>
      </div>

    </div>
    <div class="next-button">
      <router-link to="/cart/order">Перейти к загрузке документов</router-link>
    </div>
  </section>
</template>

<script>
export default {
    name: 'MobileComponent',
    props: {
        cartObjects: {
            type: Array,
            required: true
        },
        reportType: {
            type: Number,
            required: true
        },
        paperReportPrice: {
            type: Number,
            required: true
        },
        price: {
            type: Number,
            required: true
        },
        deleteEvent: {
            type: Function,
            required: true
        },
        changeReportTypeEvent: {
            type: Function,
            required: true
        }
    },
};
</script>

<style scoped>

  .switch-report-type label {
    font-size: 14px;
    margin-bottom: 10px;
    display: inline-block;
    margin-right: 10px;
  }

  .switch-report-type .double-switch {
    display: inline-block;
  }

  .services .service-item {
    margin: 10px 0;
    padding: 10px;
  }

  .services .service-item:nth-child(odd) {
    background-color: #f5f5f5;
  }

  .services .service-item:nth-child(even) {
    background-color: #f5f5f5;
  }

  .services .service-item-header .name {
    display: inline-block;
  }

  .services .service-item .name span {
    margin-bottom: 10px;
    color: #000;
    display: block;
  }

  .services .service-item .name a {
    border-bottom: 1px dashed #000000;
    text-decoration: none;
    display: inline-block;
    margin-right: 5px;
    color: #000;
    margin-bottom: 10px;
  }

  .services .service-item .name a:hover {
    border-bottom: 1px dashed transparent;
  }

  .services .service-item .name svg {
    color: #b1b1b1;
  }

  .services .service-item-body {
    margin-bottom: 5px;
  }

  .services .service-item-body .image,
  .services .service-item-body .price,
  .services .service-item-body .action {
    display: inline-block;
    vertical-align: middle;
  }

  .services .service-item-body .image {
    width: 30%;
  }

  .services .service-item-body .price {
    width: 55%;
    text-align: center;
  }

  .services .service-item-body .action {
    width: 15%;
    text-align: right;
  }

  .services .service-item-body .price_sale {
    color: red;
    text-decoration: line-through;
    margin-right: 5px;
  }

  section .next-button {
    max-width: 300px;
    margin: 10px auto;
  }

  section .next-button>a {
    display: block;
    text-align: center;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    font-weight: bold;
    background-color: #3194ca;
    color: #ffffff;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    border: none;
    text-decoration: none;
  }

</style>
