<template>
    <div class="action-bar">
      <ul>
        <li v-if="isLoggedIn" class="notify">
          <a href="#" @click="handleShowNotify">
            <font-awesome-icon icon="bell" />
            <span v-if="notifyCountNotRead" class="exist">
              {{notifyCountNotRead}}
            </span>
          </a>
          <notification-component
            v-if="showNotify"
            :items="notifyItems"
            :hide-event="handleHideNotify"
          />
        </li>
        <li class="cart">
          <router-link to="/cart">
            <font-awesome-icon icon="cart-plus" />
            <span v-if="cartItems">{{cartItems}}</span>
          </router-link>
        </li>
        <li :class="isUserActive()?'active':''">
          <a href="#" @click="handleShowMenu">
            <font-awesome-icon icon="user-circle" />
          </a>
          <user-menu-component v-if="isLoggedIn && showUserMenu" />
        </li>
      </ul>
    </div>
</template>

<script>
import { computed, ref, provide, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { AUTH_SHOW, CART_GET_CART_DEFAULT } from "../../store/constants";
import { getAllRequest } from "../../api/notifications";
import UserMenuComponent from "./UserMenuComponent";
import NotificationComponent from "./NotificationComponent";

export default {
  components: {
      NotificationComponent,
      UserMenuComponent
  },
  setup() {

      const store = useStore();
      const auth = store.state.auth;
      const showUserMenu = ref(false);
      const cart = store.getters[CART_GET_CART_DEFAULT];
      const notifyItems = ref([]);
      const notifyCountNotRead = ref(0);
      const showNotify = ref(false);

      const route = useRoute();

      const handleShowMenu = (event) => {
          event.preventDefault();
          if(!auth.isLoggedIn) {
              store.commit(AUTH_SHOW, { to: route });
          } else {
              showUserMenu.value = true;
          }
      };

      const loadNotify = () => {
          getAllRequest()
              .then(response => {
                  notifyItems.value = response.data.items;
                  notifyCountNotRead.value = response.data.countNotRead;
              })
              .catch(err => console.log(err));
      };

      const handleShowNotify = (e) => {
          e.preventDefault();
          showNotify.value = true;
      };

      const handleHideNotify = () => {
          showNotify.value = false;
      };

      const isUserActive = () => {
          if(route.path === '/orders' || route.path === '/settings/profile') {
              return true;
          }
          return false;
      };

      onMounted(() => {
          if(auth.isLoggedIn) {
              loadNotify();
          }
      });

      provide('showUserMenu', showUserMenu);
      provide('notifyCountNotRead', notifyCountNotRead);

      return {
          isLoggedIn: computed(() => auth.isLoggedIn),
          handleShowMenu,
          showUserMenu,
          cartItems: computed(() => cart.items.length),
          showNotify,
          notifyItems,
          notifyCountNotRead,
          handleShowNotify,
          handleHideNotify,
          isUserActive
      }
  }
}
</script>

<style scoped>

  .action-bar {
    display: inline-block;
    vertical-align: middle;
  }

  .action-bar>ul>li {
    position: relative;
    display: inline-block;
  }

  .action-bar>ul>li>a {
    font-size: 30px;
    padding: 12px;
    display: block;
    color: #afafaf;
    text-decoration: none;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
    -ms-transition: All 0.3s ease;
  }

  .action-bar>ul>li>a:hover,
  .action-bar>ul>li.active>a {
    color: #3294ca;
  }

  .action-bar>ul>li a.active {
    color: #3294ca;
  }

  .action-bar .cart span,
  .action-bar .notify span {
    position: absolute;
    background-color: #3294ca;
    padding: 5px 8px;
    font-size: 12px;
    font-weight: bold;
    width: max-content;
    line-height: 12px;
    border-radius: 30px;
    right: 0;
    top: 5px;
    color: #ffffff;
  }

  .action-bar li .exist {
    background-color: #ca3232;
  }

</style>
